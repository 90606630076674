<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          id="start-date-group"
          :label="label || 'Du'"
          label-for="start-date"
          description="Date de début de période"
        >
          <b-form-input type="date" id="start-date" v-model="startDateValue" :disabled="disabled">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="end-date-group"
          label="Au"
          label-for="end-date"
          description="Date de fin de période"
        >
          <b-form-input type="date" id="end-date" v-model="endDateValue" :disabled="disabled">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'DateFrameSelector',
  props: {
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  components: {
  },
  data() {
    return {
      startDateValue: null,
      endDateValue: null,
    }
  },
  watch: {
    startDate: function() { this.startDateValue = this.startDate },
    endDate: function() { this.endDateValue = this.endDate },
    startDateValue: function() { this.onDateRangeChanged() },
    endDateValue: function() { this.onDateRangeChanged() },
    disabled: function() {},
  },
  computed: {
  },
  created() {
    this.startDateValue = this.startDate
    this.endDateValue = this.endDate
  },
  methods: {
    onDateRangeChanged() {
      this.$emit('change', { startDate: this.startDateValue, endDate: this.endDateValue, })
    },
  },
}
</script>
<style scoped lang="less">
</style>
